import axios from 'axios'
import router from '../router'
// import qs from 'qs'

axios.defaults.baseURL = ''; // http://47.251.39.3:8089/
axios.defaults.secondURL = sessionStorage.getItem('imgurl');
axios.defaults.timeout = 30000;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 请求拦截
axios.interceptors.request.use(function (config) {
    // if (store.state.login.token) {
    //     config.url += ('?token=' + `${store.state.login.token}`)
    // }
    if (sessionStorage.getItem("formToken")) {
        let formToken = sessionStorage.getItem("formToken")
        let timestamp = sessionStorage.getItem("timestamp")
        config.headers.common['formToken'] = formToken
        config.headers.common['timestamp'] = timestamp
    }
    if (config.method == 'post') {
        // config.data = qs.stringify(config.data)
    }
    return config;
}, function (error) {
    alert('网络错误,请稍后再试');
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    setTimeout(() => {
    }, 300)
    if (response.data) {
        // router.push({
        //     path: 'login',
        //     query: { redirect: router.currentRoute.fullPath }
        // })
    }
    return response;
}, function (error) {
    if (error.response) {
        if (error.response.status == 401) {
            alert('请重新登录');
            router.push({
                path: 'login',
                query: { redirect: router.currentRoute.fullPath }
            })
        }
    }
    return Promise.reject(error);
});

export default axios;