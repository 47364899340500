import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
//按需加载,当渲染其他页面时才加载其组件,并缓存,减少首屏加载时间

const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/', redirect: { path: '/About' } },
    {
      path: '/About',
      name: 'About', //首页
      component: (resolve) => require(['@/views/About.vue'], resolve),
      meta: { title: 'About' },
    },
    {
      path: '/Shutters',
      name: 'Shutters', //Shutters
      component: (resolve) => require(['@/views/Shutters.vue'], resolve),
      meta: { title: 'Shutters' },
    },
    {
      path: '/TradeArea',
      name: 'TradeArea', //TradeArea
      component: (resolve) => require(['@/views/TradeArea.vue'], resolve),
      meta: { title: 'TradeArea' },
    },
    {
      path: '/Gallery',
      name: 'Gallery', //Gallery
      component: (resolve) => require(['@/views/Gallery.vue'], resolve),
      meta: { title: 'Gallery' },
    },
    {
      path: '/Contact',
      name: 'Contact', //Contact
      component: (resolve) => require(['@/views/Contact.vue'], resolve),
      meta: { title: 'Contact' },
    },
    {
      path: '/ShutterComponents',
      name: 'ShutterComponents', //ShutterComponents
      component: (resolve) => require(['@/views/ShutterComponents.vue'], resolve),
      meta: { title: 'Shutter Components' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    document.title = 'MOONA SHUTTER - ' + to.meta.title
  }
  next()
})

export default router
