<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted: function () {
    // const that = this
    // window.screenWidth = document.body.clientWidth
    // that.screenWidth = window.screenWidth
    // window.onresize = () => {
    // that.zoomPage()
    // 或者
    // document.documentElement.style.zoom = 1 / window.devicePixelRatio
    // }
  },
  methods: {
    zoomPage() {
      // let a = this.getZoom()
      // console.log(a);
    },
    getZoom() {
      let ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase()
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth
      }
      if (ratio) {
        ratio = Math.round(ratio * 100)
      }
      return ratio
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  min-width: 1440px;
}
</style>
