import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/common.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import './assets/css/public.less'
import axios from '@/api/apiConfig'
Vue.prototype.$http = axios

import VueAMap from 'vue-amap'
Vue.use(VueAMap)
Vue.config.productionTip = false
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '2dd24438756de188fdeaacdb8414eb04',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4',
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
